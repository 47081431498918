import { db } from './firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { useCallback, useEffect, useState } from "react"
import Quill from "quill"
import "quill/dist/quill.bubble.css"
import { getAuth } from 'firebase/auth';

await getAuth()

//get url params for notebook
const searchParams = new URLSearchParams(window.location.search);
const user = searchParams.get('u');
const nb = searchParams.get('nb');
const docRef = doc(db, "users", user, "notebooks", nb);

export default function TitleBar() {
    const [getData, setGetData] = useState(true);
    const [title, setTitle] = useState(null);

    //title changes
    useEffect(() => {
        if(getData == true){
            getDoc(docRef)
            .then((docSnapshot) => {
                if (docSnapshot.exists) {
                    const data = docSnapshot.data();
                    // Access document fields here
                    setTitle(data.name)
                    document.title = data.name;
                } else {
                    // Document not found
                    console.log("No such document!");
                    document.title = "Notebook";
                }
            })
            .catch((error) => {
                console.error("Error getting document:", error);
                document.title = "Notebook";
            });
            setGetData(false);
        }
    },[getData])

    return (
    <div className="title-container">
        <input className="titlebar" defaultValue={title} onChange={e => {
            console.log(e.target.value)
            updateDoc(docRef, {
                name: e.target.value
            })
            document.title = title;
        }}></input>
    </div>
    );
}
