import { useEffect, useState, useCallback } from 'react';
import { db } from './firebaseConfig';
import { doc, getDoc, getDocs, collection, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore'
import { getAuth } from 'firebase/auth';

await getAuth()

//get params to access the users notebook
const searchParams = new URLSearchParams(window.location.search);
const user = searchParams.get('u');
const nb = searchParams.get('nb');
const docRef = doc(db, "users", user)
const inspos = []
try {
    const querySnapshot = await getDocs(collection(db, "users", user, "inspos"));
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        inspos.push(doc.data())
    });
} catch {
    inspos.push(null)
}
  

//react export
export default function FocusBar() {
    const [focuses, setFocuses] = useState([]);
    const [notebookFocuses, setNotebookFocuses] = useState([]);
    const [focusList, setFocusList] = useState([]);
    const [notebookInspos, setNotebookInspos] = useState([]);
    const [getData, setGetData] = useState(true);
    const [showFocusOptions, setShowFocusOptions] = useState(false);
    const [active, setActive] = useState(0);

    function displayFocuses(){
        setShowFocusOptions(!showFocusOptions)
    }

    //get the data for focuses and inspirations
    useEffect(() => {
        if( getData == true){
            //get focuses
            getDoc(docRef)
                .then((docSnapshot) => {
                    if (docSnapshot.exists) {
                        const data = docSnapshot.data();
                        // Access document fields here
                        setFocuses(data.userFocuses)
                    } else {
                        // Document not found
                        console.log("No such document!");
                        setFocuses([null])
                    }
                })
                .catch((error) => {
                    console.error("Error getting document:", error);
            });

            //get global, unused, focuses
            getDoc(doc(db, "assets", "focus-options"))
                .then((docSnapshot) => {
                    if (docSnapshot.exists) {
                        const data = docSnapshot.data();
                        // Access document fields here
                        setFocusList(data.options)
                    } else {
                        // Document not found
                        console.log("No such document!");
                        setFocuses([null])
                    }
                })
                .catch((error) => {
                    console.error("Error getting document:", error);
            });

            //get notebook set focuses
            getDoc(doc(db, "users", user, "notebooks", nb))
                .then((docSnapshot) => {
                    if (docSnapshot.exists) {
                        const data = docSnapshot.data();
                        // Access document fields here
                        if( data.focuses == null){
                            setNotebookFocuses(["undefined xzy"])
                        } else {
                            setNotebookFocuses(data.focuses)
                        }
                        if( data.inspos == null){
                            setNotebookInspos([{'title': "undefined xzy"}])
                        } else {
                            setNotebookInspos(data.inspos)
                        }
                        
                    } else {
                        // Document not found
                        console.log("No such document!");
                        setNotebookFocuses([null])
                        setNotebookInspos([null])
                    }
                })
                .catch((error) => {
                    console.error("Error getting document:", error);
            });
            setGetData(false)
        }
    },[getData]);

    return(
        <div className='focus-list'>
            {active === 0 ?
                <div className='add-focus hoverable' onClick={displayFocuses}>+</div>
            :
                <div className='add-focus-on hoverable' onClick={displayFocuses}>+</div>
            }
            {showFocusOptions &&
                <div className='focus-add'>
                    {focusList.map((focus, idx) =>
                        notebookFocuses.includes(focus) ?
                        !focuses.includes(focus) && <div className='focus-item-on margin-focus hoverable' key={idx} onClick={() => {
                                setActive(active + 1)
                                updateDoc(doc(db, "users", user, "notebooks", nb), {
                                    focuses: arrayRemove(focus)
                                })
                                .then(setGetData(true))
                                setTimeout(() => {
                                    setGetData(true)
                                },100)
                            }}>{focus[0].toUpperCase() + focus.slice(1)}
                        </div>
                    :
                        !focuses.includes(focus) && <div className='focus-item margin-focus hoverable' key={idx} onClick={() => {
                                setActive(active - 1)
                                updateDoc(doc(db, "users", user, "notebooks", nb), {
                                    focuses: arrayUnion(focus)
                                })
                                .then(setGetData(true))
                                setTimeout(() => {
                                    setGetData(true)
                                },100)
                            }}>{focus[0].toUpperCase() + focus.slice(1)}
                        </div>    
                    )}
                </div>
            }
            {focuses.map((focus, idx) =>
                notebookFocuses.includes(focus) ?
                    <div className='focus-item-on hoverable' key={idx} onClick={() => {
                            updateDoc(doc(db, "users", user, "notebooks", nb), {
                                focuses: arrayRemove(focus)
                            })
                            .then(setGetData(true))
                            setTimeout(() => {
                                setGetData(true)
                            },100)
                        }}>{focus[0].toUpperCase() + focus.slice(1)}
                    </div>
                :
                    <div className='focus-item hoverable' key={idx} onClick={() => {
                            updateDoc(doc(db, "users", user, "notebooks", nb), {
                                focuses: arrayUnion(focus)
                            })
                            .then(setGetData(true))
                            setTimeout(() => {
                                setGetData(true)
                            },100)
                        }}>{focus[0].toUpperCase() + focus.slice(1)}
                    </div>    
            )}
            <div className='divider' />
            {inspos.map((inspo, idx) => 
                notebookInspos.some(e => e.title === inspo.title) ?
                    <div className='focus-item-on hoverable' key={idx} onClick={() => {
                            updateDoc(doc(db, "users", user, "notebooks", nb), {
                                inspos: arrayRemove(inspo)
                            })
                            .then(setGetData(true))
                            setTimeout(() => {
                                setGetData(true)
                            },100)
                        }}>{inspo.title}
                    </div>
                :
                    <div className='focus-item hoverable' key={idx} onClick={() => {
                            updateDoc(doc(db, "users", user, "notebooks", nb), {
                                inspos: arrayUnion(inspo)
                            })
                            .then(setGetData(true))
                            setTimeout(() => {
                                setGetData(true)
                            },100)
                        }}>{inspo.title}
                    </div>

            )}
        </div>
    );
}
